<template>
  <div class="bagWorkSet" v-loading="loading">
    <article class="container">
      <section class="title">
        <div class="flex">
          <div class="bold">作业内容</div>
          <div class="time">共计：{{list.length}}个作业</div>
        </div>
        <div class="tips">
          <div class="time">
            <el-date-picker v-model="dateStart" type="datetime" value-format="yyyy-MM-dd HH:mm"
                            format="yyyy-MM-dd HH:mm" :clearable="false" disabled></el-date-picker>
            <div class="text">至</div>
            <el-date-picker
                v-model="dateEnd"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm"
                format="yyyy-MM-dd HH:mm"
                @change="dateEndChange"
                :clearable="false"></el-date-picker>
          </div>
        </div>
      </section>
      <section class="work-content">
        <div class="list" v-for="item in list" :key="item.id">
          <div class="list-content">
            <div class="left">
              <div class="flex">
                <div class="name" v-html="item.name"></div>
                <ul class="tabs" v-if="item.typeList">
                  <!--                  <li class="tab" v-for="type in item.typeList" :key="type.id">{{type.name}}</li>-->
                  <template v-for="type in item.typeList.split(',')">
                    <li class="tab" :key="type.id" v-if="type">{{type}}</li>
                  </template>
                </ul>
              </div>
              <div class="module" v-html="item.type"></div>
            </div>
            <div class="middle">
              <div class="flex">
                <el-date-picker
                    v-model="item.dateStart"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm"
                    format="yyyy-MM-dd HH:mm"
                    :clearable="false"
                    disabled></el-date-picker>
                <div class="text">至</div>
                <el-date-picker
                    v-model="item.dateEnd"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm"
                    format="yyyy-MM-dd HH:mm"
                    :clearable="false"
                    @change="itemDateEndChange"></el-date-picker>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="title-column">编辑作业名称</section>
      <section class="input-column">
        <div class="input">
          <div class="icon"></div>
          <input v-model="name" placeholder="请输入本次作业名称" type="text"/>
        </div>
      </section>
    </article>
    <footer class="footer">
      <div class="submit-btn" @click="submit">保存</div>
    </footer>
  </div>
</template>

<script>
  export default {
    name: 'bagWorkSet',
    data() {
      return {
        loading: false,
        name: '',
        list: [],
        dateStart: null,
        dateEnd: null
      }
    },
    created() {
      this.id = this.$route.query.id
      this.bagDetail()
    },
    methods: {
      bagDetail() {
        this.loading = true
        this.$axios({
          method: 'post',
          url: this.$urls.bagDetail,
          data: {
            bagId: this.id
          }
        })
          .then((response) => {
            this.loading = false
            if (response.state === '11') {
              const {data} = response
              this.name = data.name
              this.dateStart = this.common.formatDate('yyyy-MM-dd hh:mm', new Date(data.dateStart))
              this.dateEnd = this.common.formatDate('yyyy-MM-dd hh:mm', new Date(data.dateEnd))
              const {itemList} = data
              for (let i = 0, len = itemList.length; i < len; i += 1) {
                itemList[i].dateStart = this.common.formatDate('yyyy-MM-dd hh:mm', new Date(itemList[i].dateStart))
                itemList[i].dateEnd = this.common.formatDate('yyyy-MM-dd hh:mm', new Date(itemList[i].dateEnd))
              }
              this.list = itemList
            }
          })
          .catch(() => {
            this.loading = false
          })
      },
      dateEndChange(val) {
        const {list} = this
        for (let i = 0, len = list.length; i < len; i += 1) {
          list[i].dateEnd = val
        }
      },
      itemDateEndChange(val) {
        const valTime = new Date(val).getTime()
        const dateEnd = new Date(this.dateEnd).getTime()
        if (valTime > dateEnd) {
          this.dateEnd = val
        }
      },
      submit() {
        const dateStart = this.common.binarySystem(this.dateStart)
        let dateEnd = this.common.binarySystem(this.dateEnd)
        const start = new Date(dateStart).getTime()
        const end = new Date(dateEnd).getTime()
        if (end <= start) {
          this.$alert('截止时间应大于开始时间', '提示', {
            confirmButtonText: '确定'
          })
          return
        }

        const name = this.name.trim()
        if (name === '') {
          this.$alert('请输入作业名称', '提示', {
            confirmButtonText: '确定'
          })
          return
        }

        const {list} = this
        const itemList = []
        for (let i = 0, len = list.length; i < len; i++) {
          const {id} = list[i]
          const obj = {}
          obj.id = id
          const itemDateStart = this.common.binarySystem(list[i].dateStart)
          const itemDateEnd = this.common.binarySystem(list[i].dateEnd)
          const dateStartTime = new Date(itemDateStart).getTime()
          const dateEndTime = new Date(itemDateEnd).getTime()
          if (dateEndTime <= dateStartTime) {
            this.$alert('单个作业截止时间应大于开始时间', '提示', {
              confirmButtonText: '确定'
            })
            return
          }
          obj.dateEnd = this.common.formatDate('yyyy-MM-dd hh:mm:ss', new Date(itemDateEnd))
          itemList.push(obj)
        }
        dateEnd = this.common.formatDate('yyyy-MM-dd hh:mm:ss', new Date(dateEnd))
        this.$axios({
          method: 'post',
          url: this.$urls.updateBagItem,
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            id: this.id,
            dateEnd,
            itemList,
            name
          }
        })
          .then((response) => {
            if (response.state === '11') {
              this.$store.commit('includeDel', 'checkWork')
              this.common.globalData.checkByTeacherRefresh = true
              this.common.globalData.userWorkRefresh = true
              this.$message({
                message: '保存成功！',
                type: 'success'
              })
              this.$router.back()
            }
          })
      }
    }
  }
</script>

<style lang="scss">
  .bagWorkSet {
    .el-date-editor--datetime {
      width: 120px;
      font-size: 15px;

      .el-input__inner {
        border: none;
        padding: 0;
        text-decoration: underline;
      }

      .el-input__prefix {
        display: none;
      }
    }
  }
</style>
<style lang="scss" scoped>
  .container {
    max-width: 1100px;
    height: calc(100vh - 164px);
    margin: 0 auto;
    overflow: auto;
    position: relative;
    z-index: 99;

    .title {
      @include flex(space-between);
      height: 84px;
      @include font(20px);
      padding: 0 28px;

      .flex {
        @include flex(flex-start);
        width: 558px;
      }

      .bold {
        font-weight: bold;
      }

      .time {
        margin-left: 56px;
        font-size: 17px;
      }

      .tips {
        @include font(15px, #FF3C30);
        text-decoration: underline;
        cursor: pointer;

        .time {
          @include flex;
          font-size: 15px;
          color: #999;

          .text {
            margin: 0 10px;
          }
        }
      }
    }

    .work-content {
      background-color: #fff;
      padding: 10px 28px;

      .list {
        padding: 0 28px;
        border-radius: 8px;

        &:hover {
          background-color: #F6F6F6;
        }

        .list-content {
          @include flex(space-between);
          height: 88px;
          border-bottom: 1px solid #F1F1F1;
          @include font(17px);

          .left {
            flex-shrink: 0;
            width: 502px;

            .flex {
              @include flex(flex-start);
              margin-bottom: 10px;

              .name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .tabs {
                @include flex;
                margin-left: 20px;

                .tab {
                  background-color: #EAF4FD;
                  height: 26px;
                  line-height: 26px;
                  padding: 0 10px;
                  border-radius: 4px;
                  margin-right: 10px;
                  @include font(14px, #309AF2, center);
                  white-space: nowrap;

                  &:last-child {
                    margin-right: 0;
                  }
                }
              }
            }

            .module {
              @include font(15px, #999);
            }
          }

          .middle {
            color: #999999;
            font-size: 15px;

            .flex {
              @include flex;
            }

            .text {
              margin: 0 10px;
            }
          }
        }
      }
    }

    .title-column {
      height: 84px;
      line-height: 84px;
      padding-left: 28px;
      font-size: 20px;
      font-weight: bold;
    }

    .input-column {
      @include flex(flex-start);
      background-color: #fff;
      height: 84px;
      padding-left: 28px;

      .input {
        @include flex;
        background-color: #F6F6F6;
        width: 500px;
        height: 44px;
        padding: 0 20px;
        border-radius: 22px;

        .icon {
          @include icon(url(../../../assets/input.png), 20px, 20px);
        }

        input {
          flex-grow: 1;
          font-size: 17px;
          background: none;
          outline: none;
          border: none;
          padding-left: 10px;
        }
      }
    }
  }

  .footer {
    @include flex;
    background-color: #fff;
    max-width: 1100px;
    height: 84px;
    margin: 0 auto;
    box-shadow: 0 -6px 15px 0 rgba(0, 0, 0, .06);
    position: relative;
    z-index: 100;

    .submit-btn {
      background: linear-gradient(to right, #6DBBFC, #309AF2);
      width: 250px;
      height: 46px;
      line-height: 46px;
      border-radius: 4px;
      @include font(17px, #fff, center);
      cursor: pointer;

      &:hover {
        background: #309AF2;
      }

      &:active {
        background: #6DBBFC;
      }
    }
  }
</style>
